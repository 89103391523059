<template>
  <b-modal id="editarCupom" hide-footer centered size="xl" title="Editar Cupom">
    <b-card>
      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div
          class="alert-body"
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>

      <div class="d-flex">
        <feather-icon icon="TagIcon" size="19" />
        <h4 class="mb-2 ml-50">Detalhes do Cupom</h4>
      </div>

      <validation-observer ref="cupomCadastroValidation">
        <b-form class="mt-1">
          <b-row>
            <b-col md="6">
              <b-form-group label="Código *" label-for="cod">
                <validation-provider
                  #default="{ errors }"
                  name="Código"
                  rules="required|max:10"
                >
                  <b-form-input
                    id="cod"
                    v-model="form.cod"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Descrição" label-for="descricao">
                <validation-provider
                  #default="{ errors }"
                  name="Descrição"
                  rules="required|max:100"
                >
                  <b-form-input
                    id="descricao"
                    v-model="form.descricao"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Validade"
                rules="required"
              >
                <b-form-group label="Validade" label-for="validade">
                  <b-form-datepicker
                    id="validade"
                    v-model="form.validade"
                    :date-format-options="{
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                    }"
                    close-button
                    hide-header
                    label-close-button="Fechar"
                    label-reset-button="Apagar Data"
                    label-help=""
                    locale="pt-BR"
                    placeholder="Data"
                    reset-button
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider
                  #default="{ errors }"
                  name="Dias Validade"
                  rules="required|between:1,999"
              >
                <b-form-group label="Dias Validade" label-for="dias_validade">
                  <b-form-input
                      id="dias_validade"
                      v-model.number="form.dias_validade"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Desconto"
                rules="required|between:1,9999"
              >
              <b-form-group label="Quantidade" label-for="quantidade">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      class="py-0"
                      @click="form.quantidade -= 1 "
                    >
                      <b-icon icon="dash" font-scale="1.6" />
                    </b-button>
                  </b-input-group-prepend>

                  <b-form-input
                    id="id"
                    v-model="form.quantidade"
                    type="number"
                    min="1"
                    class="text-center"
                    number
                  />

                  <b-input-group-append>
                    <b-button
                      variant="outline-secondary"
                      class="py-0"
                      @click="form.quantidade += 1"
                    >
                      <b-icon icon="plus" font-scale="1.6" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Desconto"
                rules="required|between:0,100"
              >
                <b-form-group label="Desconto" label-for="desconto">
                  <b-input-group prepend="%">
                    <b-form-input
                      type="number"
                      id="desconto"
                      v-model="form.desconto"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="submit"
                @click="gravaAlteracoes"
              >
                <b-spinner small v-if="spinner" />
                <span v-if="spinner">Aguarde...</span>
                <span v-else>Confirmar Alterações</span>
              </b-button>

              <b-button
                variant="outline-secondary"
                @click="$bvModal.hide('editarCupom')"
              >
                Fechar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormDatepicker,
  BFormSpinbutton,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BRow,
  BSpinner,
  BIcon,
  BIconDash,
  BIconPlus,
  BInputGroupPrepend,
} from "bootstrap-vue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import Cleave from "vue-cleave-component";

// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BFormDatepicker,
    BFormSpinbutton,
    BInputGroup,
    BSpinner,
    BAlert,
    BModal,
    BFormSelect,
    BIcon,
    BIconDash,
    BIconPlus,
    BInputGroupPrepend,
    Cleave,
  },

  props: ["form", "cupons"],

  data() {
    return {
      required,
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      spinner: false,
      submit: false,
      errors: false,
    };
  },

  methods: {
    gravaAlteracoes() {
      this.$refs.cupomCadastroValidation.validate().then((success) => {
        if (success) {
          this.spinner = true;
          this.submit = true;
          this.errors = false;

          let index = this.cupons.indexOf(this.form);

          this.$http
            .put("adm/cupons/editar/" + this.form.id, this.form)
            .then((res) => {
              let resposta = {
                index: index,
                dados: this.form,
              };

              this.$store.commit("cupons/EDITAR_CUPOM", resposta);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Cupom Cadastrado com Sucesso!",
                  icon: "InfoIcon",
                  variant: "success",
                },
              });
              this.getErrors = false;
              this.$refs.cupomCadastroValidation.reset();
              this.fecharModal();
            })
            .catch((error) => {
              let res = this.$responseMessages.getMessage(error, null);
              this.getErrors = res;
            })
            .finally(() => {
              this.spinner = false;
              this.submit = false;
            });
        }
      });
    },
    fecharModal() {
      this.$bvModal.hide("editarCupom");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
