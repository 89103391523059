<template>
  <div>
    <b-row align-h="end">
      <b-col md="5" class="mb-1">
        <b-form-group label-for="filterInput" class="mb-0">
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Procurar"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          striped
          hover
          responsive
          :items="cupons"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
        >
        <template #cell(validade)="data">
          {{data.item.validade | formataData}}
        </template>

          <template #cell(opcoes)="data">
            <b-button
              class="btn-icon"
              variant="flat-secondary"
              style="padding: 2px"
              @click="editarCupom(data.item)"
            >
              <feather-icon icon="Edit2Icon" size="16" />
            </b-button>

            <b-button
              class="btn-icon text-danger"
              variant="flat-secondary"
              style="padding: 2px"
              @click="excluirCupom(data.item.id, data.index)"
            >
              <feather-icon icon="Trash2Icon" size="16" />
            </b-button>
          </template>
        </b-table>
      </b-col>

      <modal-editar-cupom :form="editar" :cupons="cupons"/>
    </b-row>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapState } from "vuex";
import ModalEditarCupom from './ModalEditarCupom.vue';
//import ModalEditarConta from "./ModalEditarConta.vue";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BFormCheckbox,
    //ModalEditarConta,
    ToastificationContent,
    ModalEditarCupom,
  },
  data() {
    return {
      editar: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: ["nome", "descricao", "validade", "quantidade", "desconto"],
      boxTwo: "",

      fields: [
        { key: "cod", label: "Código", sortable: true },
        { key: "descricao", label: "Descrição", thStyle: {width: "30%"}, sortable: true },
        { key: "validade", label: "Validade", sortable: true },
        { key: "quantidade", label: "QTD", sortable: true },
        { key: "desconto", label: "Desc", sortable: true },
        { key: "dias_validade", label: "Dias Validade" },
        { key: "opcoes", label: "Opções", thStyle: {width: "10%"}, sortable: false },
      ],
    };
  },
 
  computed: {
    ...mapState({
      cupons: (state) => state.cupons.cupons,
      // user: state => state.user.user
    }),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  mounted() {
    this.buscaDados();
  },

  methods: {
    editarCupom(cupom) {
      this.editar = cupom;
      this.$bvModal.show("editarCupom");
    },
    buscaDados() {
      this.$store.dispatch("cupons/listaCupons");
    },
    excluirCupom(id, index) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Deseja realmente excluir esse Cupom ?", {
          title: "Excluir Cupom",
          size: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((valor) => {
          if (valor) {
            this.$http
              .delete("adm/cupons/excluir/" + id)
              .then((res) => {
                this.$store.commit("cupons/DELETAR_CUPOM", index);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Cupom excluído com sucesso!",
                    icon: "InfoIcon",
                    variant: "success",
                  },
                });
              })
              .catch((error) => {
                this.$responseMessages.getMessage(error, null);
              });
          }
        });
    },
  },
  filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
  },
};
</script>
<style scoped>
.b-table-row-selected {
  background-color: #0b5661;
  color: #fff;
}

.espacoTd {
  width: 30px;
}
</style>
