import { render, staticRenderFns } from "./ModalCadastrarCupom.vue?vue&type=template&id=4720cfa0&scoped=true&"
import script from "./ModalCadastrarCupom.vue?vue&type=script&lang=js&"
export * from "./ModalCadastrarCupom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4720cfa0",
  null
  
)

export default component.exports