<template>
  <div style="height: inherit">
    <b-row class="mb-1">
      <b-col cols="4">
        <b-button
          style="margin-right: 5px"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="gradient-success"
          @click="cadastrarCupom"
          size="sm"
        >
          <feather-icon icon="PlusIcon" class="mr-75" />
          Cadastrar Cupom
        </b-button>

        <b-button size="sm" type="submit" variant="gradient-primary" style="margin-right: 5px">
          <feather-icon icon="PrinterIcon"></feather-icon>
        </b-button>

        <b-button size="sm" type="submit" variant="gradient-primary">
          <feather-icon icon="FileTextIcon"></feather-icon>
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <lista-cupons/>
    </b-card>
   <modal-cadastrar-cupom/>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BAlert,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { mapState, mapGetters } from "vuex";
import ModalCadastrarCupom from './ModalCadastrarCupom.vue';
import ListaCupons from './ListaCupons.vue';

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BAlert,
    ModalCadastrarCupom,
    ListaCupons

  },
  directives: {
    Ripple,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
     
    }),
  },

  methods: {
    cadastrarCupom() {
      this.$bvModal.show("cadastrarCupom");
    },
  },

  name: "CuponsPrincipal.vue",
};
</script>

<style scoped>
</style>
